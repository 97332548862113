import { useState } from 'react'

import cn from 'classnames'

import { tr } from 'mmfintech-commons'

// import { PaymentMethodImage } from './PaymentMethodImage'
import { ButtonArrow, ButtonContent, ButtonImage, ButtonWrapper } from './PaymentMethodButton.styled'

import { ArrowRightIcon } from '../../icons'

type PaymentMethodButtonProps = {
  name: string
  description?: string
  onClick: () => void
  onContinue?: () => void
  selected: boolean
  disabled?: boolean
}

export const PaymentMethodButton = (props: PaymentMethodButtonProps) => {
  const { name, description, selected, onClick, onContinue, disabled } = props

  const [locked, setLocked] = useState<boolean>(false)

  const handleClick = () => {
    if (disabled || locked) return

    if (selected) {
      onContinue()
      setLocked(true)
      setTimeout(() => setLocked(false), 3000)
    } else {
      onClick()
    }
  }

  return (
    <ButtonWrapper
      className={cn({ selected, disabled })}
      onClick={handleClick}
      onDoubleClick={e => {
        e.preventDefault()
        e.stopPropagation()
        console.log('double click')
      }}
      data-test={`method-${name.toLowerCase()}`}>
      <ButtonImage className={cn('image-' + name.toLowerCase(), { selected })} />
      {/*<PaymentMethodImage method={name} />*/}
      <ButtonContent>
        {tr('METADATA.PAYMENT_OPTIONS.' + name, name)}
        {description && <div className='description'>{description}</div>}
      </ButtonContent>
      <ButtonArrow className='arrow' data-test={`button-${name.toLowerCase()}`}>
        <ArrowRightIcon />
      </ButtonArrow>
    </ButtonWrapper>
  )
}
