import { useState } from 'react'

import { CheckoutContainer } from '../../components'
import { PaymentMethodButton } from '../../components/PaymentMethodButton'
import { PaymentContainer, PaymentContent } from '../CheckoutPayment/Checkout.styled'

import { PaymentMethodEnum } from 'mmfintech-commons-types'

export const TestMethods = () => {
  const [selected, setSelected] = useState<string>(null)

  return (
    <CheckoutContainer>
      <PaymentContainer>
        <h2>Testing method names and logos</h2>

        <PaymentContent>
          {Object.keys(PaymentMethodEnum).map(name => {
            return (
              <PaymentMethodButton
                key={name}
                name={name}
                disabled={false}
                selected={selected === name}
                onClick={() => {
                  setSelected(name)
                  console.log('click', name)
                }}
                onContinue={() => {
                  setSelected(null)
                  console.log('continue', name)
                }}
              />
            )
          })}
        </PaymentContent>
      </PaymentContainer>
    </CheckoutContainer>
  )
}
